








import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkServerManagerClient, ServerDto} from '@/logic/ArkServerManagerClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class ServerName extends Vue {

    @Prop({required: true})
    serverId!: string | number;

    @AsyncComputedProp()
    public async server(): Promise<ServerDto | null> {
        if (this.serverId) {
            return await ArkServerManagerClient.getServer(this.serverId);
        }
        return null;
    }
}
