







import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, ResourceListDto, TribeDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class TribeName extends Vue {

    @Prop({required: true})
    tribeId!: string | number;

    @AsyncComputedProp()
    public async object(): Promise<TribeDto | null> {
        if (!this.tribeId) {
            return null;
        }
        const result = await ArkStatsBackendClient.getGenericObject<ResourceListDto<TribeDto>>('tribe', {
            limit: 1,
            fields: ['name'],
            filter: `id==${this.tribeId}`
        });
        if (!result.data || !result.data.length) {
            return {
                id: String(this.tribeId),
                name: String(this.tribeId)
            }
        }

        return result.data[0];
    }
}
