import Axios from 'axios';

export interface ServerDto {
    id: number;
    name: string;
}

export interface TribeDataDto {
    tribeId: number;
    name: string;
}

export interface TribeDto {
    tribe: TribeDataDto;
}

export interface SurvivorDto {
    playerId: number;
    survivorName: string;
}

export abstract class ArkServerManagerClient {
    private static AXIOS = Axios.create();

    static async getServer(serverId: string | number): Promise<ServerDto> {
        const url = `https://ark.domination-gaming.com/rest/server/${serverId}`;
        const response = await this.AXIOS.get<ServerDto>(url);
        return response.data;
    }

    static async getTribe(serverId: number, tribeId: number): Promise<TribeDto> {
        const url = `https://ark.domination-gaming.com/rest/server/${serverId}/tribe/${tribeId}`;
        const response = await this.AXIOS.get<TribeDto>(url);
        return response.data;
    }

    static async getSurvivor(serverId: number, survivorId: number): Promise<SurvivorDto> {
        const url = `https://ark.domination-gaming.com/rest/server/${serverId}/survivor/${survivorId}`;
        const response = await this.AXIOS.get<SurvivorDto>(url);
        return response.data;
    }
}
