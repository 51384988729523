







import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, PlayerDto, PlayersDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class PlayerName extends Vue {

    @Prop({required: true})
    characterId!: string | number;

    @AsyncComputedProp()
    public async object(): Promise<PlayerDto | null> {
        if (!this.characterId) {
            return null;
        }
        const result = await ArkStatsBackendClient.getGenericObject<PlayersDto>('player', {
            limit: 1,
            fields: ['character.name'],
            filter: `character.id==${this.characterId}`
        });
        if (!result.data || !result.data.length) {
            return {
                id: String(this.characterId),
                character: {name: String(this.characterId)}
            }
        }

        return result.data[0];
    }
}
