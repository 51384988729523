






































import {Component, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';
import DinoName from '@/components/DinoName.vue';
import ServerName from '@/components/ServerName.vue';
import TribeName from '@/components/TribeName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, DinoName, PlayerName, ServerName, TribeName}
})
export default class ItemStats extends Vue {
    private dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
    private groupBy = 'type';
    private loading = true;

    @AsyncComputedProp()
    public async items(): Promise<any> {
        this.loading = true;
        const result = await ArkStatsBackendClient.getItemsAggregate({
            groupBy: [this.groupBy],
            fields: [
                'count as stacks',
                'sum(quantity) as quantity'
            ],
            order: ['-quantity']
        });
        this.loading = false;
        return result;
    }
}

