




import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

interface IdNamePairs {
    data: IdNamePair[];
}

interface IdNamePair {
    id: string | number;
    name: string;
}

@Component({
    components: {}
})
export default class DisplayName extends Vue {

    @Prop({required: true})
    id!: string | number;

    @Prop({type: String, required: true})
    type!: string;

    @AsyncComputedProp()
    public async object(): Promise<IdNamePair | null> {
        if (!this.id || !this.type) {
            return null;
        }
        const result = await ArkStatsBackendClient.getGenericObject<IdNamePairs>(this.type, {
            limit: 1,
            fields: ['name'],
            filter: `id==${this.id}`
        });
        if (!result.data || !result.data.length) {
            return {
                id: this.id,
                name: String(this.id)
            }
        }

        return result.data[0];
    }
}
